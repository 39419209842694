import React, { useState, useEffect } from 'react'
import NavTop from '../Dashboard/Navbar/navbar';
import SearchFilter from "../Home/searchFilter/searchFilter";
import DiscoverSearch from "./DiscoverSearch/discoverSearch";
import Feeds from "../Home/Feeds/feeds";
import Papular from "../Home/papular/papular";
import FlashSale from "../Home/flashSale/flashSale";
import Email from '../Dashboard/emailSection/email'
import Mobile from '../Dashboard/mobilesection/mobile';
import Contact from '../Dashboard/contact us/contact';
import Accounts from "./Accounts/accounts";
import {useLocation} from "react-router";
import { useSelector, useDispatch } from 'react-redux'
const Discover = () => {
    const discoverSearchQuery = useSelector((state) => state.overview.discoverDropdownSearch);
    console.log(discoverSearchQuery);
    // const { pathname } = useLocation();
    // useEffect(() => {
    //     window.scrollTo(0, 0);
    // }, [pathname]);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    return (
        <>
            <NavTop />
            <DiscoverSearch/>
            <Accounts/>
            <Feeds/>
            <Papular/>
            <FlashSale/>
            <Email />
            <Mobile />
            <Contact />
        </>
    )
}

export default Discover;