import React, { useState, useEffect } from 'react'
import style from './productInfo.module.css';
import productImage from '../../../assets/images/product-girl.png'
import blackRating from '../../../assets/images/black-star.svg'
import blackLike from '../../../assets/images/black-like.svg'
import redLike from '../../../assets/images/red-like.svg';
import rightArrowImage from '../../../assets/images/white-right-arrow.svg'
import sizeGuide from '../../../assets/images/size-guide.svg'
import reviewStar from '../../../assets/images/starRating.png'
import returnImage from '../../../assets/images/return.svg'
import arrowHead from '../../../assets/images/arrow-head.svg'
import CloseModal from '../../../assets/images/modal-close.svg'
import BlackReviewStar from '../../../assets/images/startRatingBlack.png'
import smallRatingStar from '../../../assets/images/black-star.svg'
import user1 from'../../../assets/images/user1.svg'
import user2 from'../../../assets/images/user2.svg'
import sizeGuideTable from '../../../assets/images/size-guide-table.png'
import Modal from "react-bootstrap/Modal";
import ProgressBar from 'react-bootstrap/ProgressBar';
import Rating from '@mui/material/Rating';

const ProductInfo = () => {
    const [returnPolicy, setReturnPolicy] = useState(false);
    const [reviewModal, setReviewModal] = useState(false);
    const [sizeGuideModal, setSizeGuideModal] = useState(false);
    const handleSizeGuideClose = () => setSizeGuideModal(false);
    const handleReturnPolicyClose = () => setReturnPolicy(false);
    const handleReviewClose = () => setReviewModal(false);
    return (
        <>
            <section className={`${style.product_info}`}>
                <div className={`container-fluid ${style.product_info_container}`}>
                    <div className={`row ${style.product_row}`}>
                        <div className="col-md-6">
                            <div className={`${style.product_bg} position-relative`}>

                                <div className={`${style.product_img}`}>
                                    <img src={productImage} alt="" />
                                </div>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className={`${style.product_description}`}>
                                <div>
                                    <ul className="mb-4">
                                        <li>Type</li>
                                        <li>95% match</li>
                                    </ul>
                                </div>

                                <div className={`${style.product_title}`}>
                                    <h1 className="mb-5">Relax Dry Stretcha</h1>
                                </div>
                                <div className={`${style.product_price} mb-4`}>
                                    <p>$ 330.00 <span className={`${style.product_review}`}>
                                        <img src={blackRating} alt=""/>&nbsp; 4.9(99 Reviews)&nbsp;
                                        <img src={blackLike} alt=""/>&nbsp;4.9&nbsp;<img src={redLike} alt=""/></span></p>
                                </div>
                                <button className={`${style.buy_from_brand}`}>Buy from Uniqlo <img src={rightArrowImage} alt=""/></button>
                                <div className={`${style.notify_product} mt-4`}>
                                    <p>Notify me when product back to stock</p>
                                </div>
                                <div className={`${style.overview} mt-3`}>
                                    <h2>Overview</h2>
                                    <p>Quick-drying and easy to move in. Our super comfortable shorts. Easy shorts that are great for indoor or outdoor adventures.</p>
                                </div>
                                <div className={`${style.material} mt-3`}>
                                    <h2>Material</h2>
                                    <p>Quick-drying and easy to move in. Our super comfortable shorts. Easy shorts that are great for indoor or outdoor adventures.</p>
                                </div>

                                <div className={`${style.more_info}`}>
                                    <ul>
                                        <li onClick={(e)=>setSizeGuideModal(true)}><img src={sizeGuide} alt="" className={`${style.pre_img}`}/>Size Guide <span><img src={arrowHead} alt="" className="float-end"/></span></li>
                                        <li onClick={(e)=>setReturnPolicy(true)}><img src={returnImage} alt="" className={`${style.pre_img}`}/>Return Policy<span><img src={arrowHead} alt="" className="float-end"/></span></li>
                                        <li onClick={(e)=>setReviewModal(true)}><img src={reviewStar} alt="" className={`${style.pre_img}`}/>Reviews<span><img src={arrowHead} alt="" className="float-end"/></span></li>
                                    </ul>

                                </div>


                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className={`${style.comment_box}`}>
                                <label>Write a Comments</label>
                                <textarea id="w3review" name="w3review" className={`${style.product_comment}`} placeholder="Comment"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Modal show={sizeGuideModal} dialogClassName={`${style.cstm_modal}`} contentClassName={`${style.cstm_content}`} backdrop="static" keyboard={false} >
                {/*<Modal.Header closeButton className={`${style.cstm_header}`}>*/}
                {/*</Modal.Header>*/}
                <Modal.Body >
                    <div className={`${style.close_modal}`}>
                        <img src={CloseModal} alt="close" onClick={handleSizeGuideClose}/>
                    </div>
                    <div className={`${style.size_guide_section}`}>
                        <h1 className="mb-5">Size Guide</h1>
                        <img src={sizeGuideTable} alt="size guide" className="w-100"/>

                        <div className={`${style.notes}`}>
                            <h1 className="mb-4">Notes</h1>
                            <ul className="ps-0">
                                <li>1. “Product size” is the size of the product’s actual measurement.</li>
                                <li>2. Fitting will vary even for same size articles</li>
                                <li>3. Size indicated for products with tucking are measured opened up.</li>
                                <li>4. Depending on the characteristics of the fabric used, there would be an error within 1 cm.</li>
                            </ul>

                        </div>

                    </div>
                </Modal.Body>

            </Modal>

            <Modal show={returnPolicy} dialogClassName={`${style.cstm_modal}`} contentClassName={`${style.cstm_content}`} backdrop="static" keyboard={false} >
                {/*<Modal.Header closeButton className={`${style.cstm_header}`}>*/}
                {/*</Modal.Header>*/}
                <Modal.Body >
                    <div className={`${style.close_modal}`}>
                    <img src={CloseModal} alt="close" onClick={handleReturnPolicyClose}/>
                    </div>
                    <div className={`${style.return_policy_section}`}>
                        <h1 className="mb-5">Return Policy</h1>
                        <p>We accept returns of products purchased in online stores by following our Returns Policy below:</p>
                        <ul>
                            <li>1. Return within 30 days from the date of ordered through online store.</li>
                            <li>2. Products through online purchases can only be returned to the UNIQLO warehouse for getting refund by returned product in new and original, unused, and still has the price tag and invoice attached.</li>
                            <li>3. The amount refunded is based on the amount you have paid even if the discount has ended with the promotion.</li>
                            <li>4. Products can be exchanged/refunded if there is a factory error.</li>
                            <li>5. The following products cannot be exchanged/refunded for hygiene reasons: Socks, innerwear, camisole, baby products, shoes, AIRism accessories (such as masks, bed sheets, pillowcases, etc.) and other accessories unless the product was originally purchased damaged or defective product.</li>
                        </ul>
                    </div>
                </Modal.Body>

            </Modal>


            <Modal show={reviewModal} dialogClassName={`${style.cstm_modal}`} contentClassName={`${style.cstm_content}`} backdrop="static" keyboard={false} >
                {/*<Modal.Header closeButton className={`${style.cstm_header}`}>*/}
                {/*</Modal.Header>*/}
                <Modal.Body >
                    <div className={`${style.close_modal}`}>
                        <img src={CloseModal} alt="close" onClick={handleReviewClose}/>
                    </div>
                    <div className={`${style.review_section}`}>
                        <h1 className="mb-4">Review</h1>
                        <div className={`${style.review_card}`}>
                            <div className="row">
                                <div className="col-sm-6 col-md-6">
                                    <div className={`${style.rating} pt-3`}>
                                        <p className={`${style.review_stats} mb-0`}><img src={BlackReviewStar} alt="rating"/> <span>4.9</span>/5</p>
                                        <p className={`${style.number_of_reviews}`}>Based on 99 reviews</p>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-6">
                                    <div className={`${style.rating_by_star}`}>
                                        <ul className="p-0">
                                            <li>
                                                <div className="row">
                                                    <div className="col-sm-3 pe-0">
                                                    <span>1 Star</span>
                                                    </div>
                                                    <div className="col-sm-9 ps-0">
                                                    <ProgressBar now={20}  className="cstm-progress"/>
                                                    </div>
                                                </div>
                                            </li>

                                            <li>
                                                <div className="row">
                                                    <div className="col-sm-3 pe-0">
                                                        <span>2 Star</span>
                                                    </div>
                                                    <div className="col-sm-9 ps-0">
                                                        <ProgressBar now={40}  className="cstm-progress"/>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="row">
                                                    <div className="col-sm-3 pe-0">
                                                        <span>3 Star</span>
                                                    </div>
                                                    <div className="col-sm-9 ps-0">
                                                        <ProgressBar now={60}  className="cstm-progress"/>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="row">
                                                    <div className="col-sm-3 pe-0">
                                                        <span>4 Star</span>
                                                    </div>
                                                    <div className="col-sm-9 ps-0">
                                                        <ProgressBar now={80}  className="cstm-progress"/>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="row">
                                                    <div className="col-sm-3 pe-0">
                                                        <span>5 Star</span>
                                                    </div>
                                                    <div className="col-sm-9 ps-0">
                                                        <ProgressBar now={100}  className="cstm-progress"/>
                                                    </div>
                                                </div>
                                            </li>

                                        </ul>
                                    </div>
                                </div>

                            </div>


                        </div>
                        <div className="pt-5">
                        <div className="row">
                            <div className="col-md-12">
                                <div className={`${style.user_reviews}`}>
                                    <div className={`${style.user_info}`}>
                                        <img src={user1} alt="user-avatar" className={`${style.avatar}`}/>
                                        <h1 className="mb-0">Haider Ahmed</h1>
                                        <p className={`${style.review_date} mb-0`}><Rating name="size-small" defaultValue={5} size="small" readOnly className={`${style.star_rating}`}/> 29 May, 2021 <span className={`${style.review_rating}`}><img src={smallRatingStar} alt="rating"/> 5.0</span></p>
                                        <p className={`${style.review_content}`}>Lorem ipsum dolor sit amet consectetur. Vitae ac nibh in mi quam nisl ultricies duis faucibus. Blandit sed.</p>
                                        <hr style={{width:"50%",marginLeft:"13%"}}/>
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-12">
                                <div className={`${style.user_reviews}`}>
                                    <div className={`${style.user_info}`}>
                                        <img src={user2} alt="user-avatar" className={`${style.avatar}`}/>
                                        <h1 className="mb-0">Muhammad Ahmed</h1>
                                        <p className={`${style.review_date} mb-0`}><Rating name="size-small" defaultValue={5} size="small" readOnly className={`${style.star_rating}`}/> 29 May, 2021 <span className={`${style.review_rating}`}><img src={smallRatingStar} alt="rating"/> 5.0</span></p>
                                        <p className={`${style.review_content}`}>Lorem ipsum dolor sit amet consectetur. Vitae ac nibh in mi quam nisl ultricies duis faucibus. Blandit sed.</p>
                                        <hr style={{width:"50%",marginLeft:"13%"}}/>
                                    </div>

                                </div>


                            </div>
                            <div className="col-md-12">
                                <div className={`${style.user_reviews}`}>
                                    <div className={`${style.user_info}`}>
                                        <img src={user1} alt="user-avatar" className={`${style.avatar}`}/>
                                        <h1 className="mb-0">Haider Ahmed</h1>
                                        <p className={`${style.review_date} mb-0`}><Rating name="size-small" defaultValue={5} size="small" readOnly className={`${style.star_rating}`}/> 29 May, 2021 <span className={`${style.review_rating}`}><img src={smallRatingStar} alt="rating"/> 5.0</span></p>
                                        <p className={`${style.review_content}`}>Lorem ipsum dolor sit amet consectetur. Vitae ac nibh in mi quam nisl ultricies duis faucibus. Blandit sed.</p>
                                        <hr style={{width:"50%",marginLeft:"13%"}}/>
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-12">
                                <div className={`${style.user_reviews}`}>
                                    <div className={`${style.user_info}`}>
                                        <img src={user2} alt="user-avatar" className={`${style.avatar}`}/>
                                        <h1 className="mb-0">Muhammad Ahmed</h1>
                                        <p className={`${style.review_date} mb-0`}><Rating name="size-small" defaultValue={5} size="small" readOnly className={`${style.star_rating}`}/> 29 May, 2021 <span className={`${style.review_rating}`}><img src={smallRatingStar} alt="rating"/> 5.0</span></p>
                                        <p className={`${style.review_content}`}>Lorem ipsum dolor sit amet consectetur. Vitae ac nibh in mi quam nisl ultricies duis faucibus. Blandit sed.</p>
                                    </div>

                                </div>


                            </div>

                        </div>
                        </div>

                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default ProductInfo;