import React from 'react';
import style from './landing.module.css';
import LandingNav from "./landingNav/landingNav";
import Banner from "./Banner/banner";
import Catalog from "./catalog/catalog";
import WhatWeDo from "./whatWeDo/whatWeDo";
import Features from "./Features/features";
import WhoWeAre from "./whoWeAre/whoWeAre";
import UserReview from "./userReviews/userReview";
import Footer from "./Footer/footer";
const Landing = ()=>{
    return (
        <div>
            <div className={`${style.landing_page_section}`}>
            {/*<LandingNav/>*/}
            <Banner/>
            <Catalog/>
            <WhatWeDo/>
            <Features/>
            <WhoWeAre/>
            <UserReview/>
            <Footer/>
            </div>
        </div>
    );

}
export default  Landing;