import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://api.atricent.com/api/v1/main-website/',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
});

export default axiosInstance;