import React from 'react';
import style from './catalog.module.css';
import Slider from 'react-slick';
import asos from '../../../assets/images/asos.png';
import adidas from '../../../assets/images/adidas2.png';
import zara from '../../../assets/images/zara2.png';
import lacoste from '../../../assets/images/lacoste.png';
import mango from '../../../assets/images/mango.png';
import nike from '../../../assets/images/nike.png';
import lululeman from '../../../assets/images/lululemon.png';
import burberry from '../../../assets/images/burberry.png';
import webImage from '../../../assets/images/web-catalog.png';
import mobImage from '../../../assets/images/mobile-catalog.png';

const items = [
    {
        src: burberry,
        class: "burberry",
        key: 1,

    },
    {
        src: adidas,
        class: "adidas",
        key: 2,

    },
    {
        src: lacoste,
        class: "lacoste",
        key: 3,

    },
    {
        src: nike,
        class: "nike",
        key: 4,

    },
    {
        src: lululeman,
        class: "lululemon",
        key: 5

    },

    {
        src: mango,
        class: "mango",
        key: 6,

    },
    {
        src: asos,
        class: "asos",
        key: 7,

    },

    {
        src: zara,
        class: "zara",
        key: 8,

    },

];
const Catalog = () =>{
    var settings = {
        dots: false,
        infinite: true,
        arrows: false,
        speed: 1000,
        slidesToShow:8,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 4000,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 5,
                },
            },
        ],
    };
    return(
        <div>
            <section className={`${style.catalog_section} position-relative`}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className={`${style.catalog_slider}`}>
                                <h2>A peak into our extensive catalog</h2>

                                <img src={webImage} alt="" className={`${style.web_img}`}/>
                                {/*<img src={mobImage} alt="" className={`${style.mob_img}`}/>*/}
                                <div className={`${style.mob_img}`}>
                                <ul className="mb-0">
                                <Slider {...settings}>
                                    {items.map((slide, index) => (
                                        <li key={index} className={`${style[slide.class]}`}>
                                            <img src={slide.src} alt={`Slide ${index + 1}`} />
                                        </li>
                                    ))}
                                </Slider>
                                </ul>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )

}
export default Catalog;