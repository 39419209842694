import { configureStore } from '@reduxjs/toolkit'
import overviewReducer from './reducers/Slice'
import { overviewApi } from './api/API'

export const store = configureStore({
    reducer: {
        overview: overviewReducer,
        [overviewApi.reducerPath]: overviewApi.reducer,
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(overviewApi.middleware)
})