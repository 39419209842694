import React from 'react'
import style from './papular.module.css';
import productImage from "../../../assets/images/product-girl.png";
import likeImage from "../../../assets/images/white-like.svg";
import ratingImage from "../../../assets/images/white-rating.svg";
import leftArrow from "../../../assets/images/left-arrow.png"
import rightArrow from "../../../assets/images/right-arrow.png"
import { Link} from 'react-router-dom';
const Papular = () => {
    return (
        <>
            <section className={`${style.feeds_section}`}>
                <div className={`container-fluid ${style.feeds_container}`}>
                    <div className={`${style.feeds_listing}`}>
                        <h1>Popular</h1>

                        <div  className={`${style.filter_cats}`}>

                            <div className="row">
                                <div className="col-md-6">
                                    <p>Lorem ipsum dolor sit amet consectetur. Maecenas vivamus urna eu sed eget imperdiet. etur.</p>
                                </div>
                                <div className="col-md-6">
                                    <div className={ `${style.pagination}`}>
                                        <img src={leftArrow} alt=""/>
                                        <img src={rightArrow} alt=""/>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div  className={`${style.products_listing}`}>
                            <div className="row">
                                <div className="col-md-4">
                                    <div  className={`position-relative ${style.product_bg}`}>
                                        <div  className={`${style.product_img}`}>

                                            <Link  to={'/product-detail'}><img src={productImage} alt="" /></Link>
                                        </div>

                                        <div  className={`${style.product_description}`}>
                                            <div className={`${style.product_cat_info}`}>
                                                <p><span  className={`${style.product_tag}`}>Uniqla</span>  <span  className={`${style.remaining}`}><span className={`${style.product_review}`}><img src={ratingImage} alt=""/>4.9(99 Reviews)</span> <img src={likeImage} alt=""/> 4.9</span></p>
                                                <p className="pt-2"><Link  to={'/product-detail'}><span className={`${style.product_title}`}>Relax Dry Stretch</span></Link> <span  className={`${style.product_price}`}>$ 221</span></p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div  className={`position-relative ${style.product_bg}`}>
                                        <div  className={`${style.product_img}`}>

                                            <Link  to={'/product-detail'}><img src={productImage} alt="" /></Link>
                                        </div>

                                        <div  className={`${style.product_description}`}>
                                            <div className={`${style.product_cat_info}`}>
                                                <p><span  className={`${style.product_tag}`}>Uniqla</span>  <span  className={`${style.remaining}`}><span className={`${style.product_review}`}><img src={ratingImage} alt=""/>4.9(99 Reviews)</span> <img src={likeImage} alt=""/> 4.9</span></p>
                                                <p className="pt-2"><Link  to={'/product-detail'}><span className={`${style.product_title}`}>Relax Dry Stretch</span></Link> <span  className={`${style.product_price}`}>$ 221</span></p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div  className={`position-relative ${style.product_bg}`}>
                                        <div  className={`${style.product_img}`}>

                                            <Link  to={'/product-detail'}><img src={productImage} alt="" /></Link>
                                        </div>

                                        <div  className={`${style.product_description}`}>
                                            <div className={`${style.product_cat_info}`}>
                                                <p><span  className={`${style.product_tag}`}>Uniqla</span>  <span  className={`${style.remaining}`}><span className={`${style.product_review}`}><img src={ratingImage} alt=""/>4.9(99 Reviews)</span> <img src={likeImage} alt=""/> 4.9</span></p>
                                                <p className="pt-2"><Link  to={'/product-detail'}><span className={`${style.product_title}`}>Relax Dry Stretch</span></Link> <span  className={`${style.product_price}`}>$ 221</span></p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                </div>
            </section>
        </>
    )
}

export default Papular