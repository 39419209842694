import React, { useState, useEffect } from 'react';
import {
    Navbar,
    Container,
    NavbarToggler,
    Collapse,
    Nav,
    NavItem,
    NavLink,
  } from 'reactstrap';
import notificationImg from '../../../assets/images/notification.png'
import atricentlogo from '../../../assets/images/Layer 1.png'
import './navbar.css'
import { Link} from 'react-router-dom';

const NavTop = () => {

    const [isOpen, setIsOpen] = useState(false);

    const toggleNavbar = () => {
      setIsOpen(!isOpen);
    };
  
    const navbarStyle = {
      height: isOpen ? '50vh' : '8vh',
      paddingBottom:'40px',
      marginBottom:'2%'
    };
  
      return (
        <div>
            {/*<div className="lading-logo text-center pt-3 pb-3">*/}
            {/*<img src={atricentlogo} alt="" style={{height:'auto', width:'200px',margin:'auto'}}/>*/}
            {/*</div>*/}
            <section className="header-section">
                <div className="container cstm-container">
            <nav className="navbar navbar-expand-lg">
                <div className="container-fluid">
                    <a className="navbar-brand" href="#">Atricent</a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse ml-5" id="navbarSupportedContent">
                        <ul className="navbar-nav  cstm-nav me-auto mb-2 mb-lg-0 ">
                            <li className="nav-item">
                                <Link className="nav-link" to={'/'}>Home</Link>
                            </li>

                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                                   aria-expanded="false">
                                    Company
                                </a>
                                <ul className="dropdown-menu">
                                    <li><Link className="dropdown-item" to={'/privacy-policy'}>Privacy Policy</Link></li>
                                    <li><Link className="dropdown-item" to={'/eula'}>EULA</Link></li>

                                </ul>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="mailto:hello@atricent.com">Contact Us</a>
                            </li>

                        </ul>

                    </div>
                </div>
            </nav>
                </div>
            </section>

            {/*<Navbar expand="md" style={navbarStyle}>*/}
          {/*  <Container className="cstm-container">*/}
          {/*    <div style={{display:'flex', justifyContent:'space-around'}}>*/}
          {/*    <div style={{height:'100%', width:'50%',justifyContent:'center', alignItems:'center'}} className="mobile-logo">*/}
          {/*              <img src={atricentlogo} alt="" style={{height:'auto', width:'100%'}}/>*/}
          {/*          </div>*/}
          {/*    <NavbarToggler className={`NavbarToggler ${isOpen ? 'collapsed' : ''}`} onClick={toggleNavbar} style={{ height: 'auto', width: '60px'}} />*/}
          {/*    </div>*/}
          {/*    {!isOpen ? (*/}
          {/*      <Collapse isOpen={isOpen} navbar className="cstm-nav">*/}
          {/*        /!*<Nav  navbar>*!/*/}
          {/*        /!*  <NavItem>*!/*/}
          {/*        /!*    <Link className="nav-link" to={'/'}>Home</Link>*!/*/}
          {/*        /!*  </NavItem>*!/*/}
          {/*        /!*  <NavItem>*!/*/}
          {/*        /!*      <Link className="nav-link" to={'/discover'}>Discover</Link>*!/*/}
          {/*        /!*  </NavItem>*!/*/}
          {/*        /!*  <NavItem>*!/*/}
          {/*        /!*    <NavLink href="#">Wardrobe</NavLink>*!/*/}
          {/*        /!*  </NavItem>*!/*/}
          {/*        /!*</Nav>*!/*/}
          {/*        <div style={{ width: '60%',height:'8vh', display: 'flex', justifyContent:'center', marginLeft:'2%', marginBottom:'0' }}>*/}
          {/*          <div style={{width:'30%',height:'100%', display:'flex', justifyContent:'center',alignItems:'center'}}>*/}
          {/*              <img src={atricentlogo} alt="" style={{height:'auto', width:'70%'}}/>*/}
          {/*          </div>*/}
          {/*        </div>*/}
          {/*        /!*<Nav className="ml-auto" navbar>*!/*/}
          {/*        /!*  <NavItem>*!/*/}
          {/*        /!*    <NavLink href="#">Contact</NavLink>*!/*/}
          {/*        /!*  </NavItem>*!/*/}
          {/*        /!*    <NavItem>*!/*/}
          {/*        /!*        <NavLink href="#"><img src={notificationImg} alt="" className="notify-img"/></NavLink>*!/*/}
          {/*        /!*    </NavItem>*!/*/}
          {/*        /!*  <NavItem>*!/*/}
          {/*        /!*    <NavLink href="#">Login</NavLink>*!/*/}
          {/*        /!*  </NavItem>*!/*/}
          {/*        /!*</Nav>*!/*/}
          {/*      </Collapse>*/}
          {/*    ) : (*/}
          {/*      <div style={{ display: 'flex', alignItems: 'center' }}>*/}

          {/*        <Nav navbar>*/}
          {/*          /!*  <NavItem>*!/*/}
          {/*          /!*      <Link className="nav-link" to={'/'}>Home</Link>*!/*/}
          {/*          /!*  </NavItem>*!/*/}
          {/*          /!*  <NavItem>*!/*/}
          {/*          /!*      <Link className="nav-link" to={'/discover'}>Discover</Link>*!/*/}
          {/*          /!*  </NavItem>*!/*/}
          {/*          /!*<NavItem>*!/*/}
          {/*          /!*  <NavLink href="#">Wardrobe</NavLink>*!/*/}
          {/*          /!*</NavItem>*!/*/}
          {/*          /!*<NavItem>*!/*/}
          {/*          /!*  <NavLink href="#">Contact</NavLink>*!/*/}
          {/*          /!*</NavItem>*!/*/}
          {/*            <NavItem>*/}
          {/*                <NavLink href="#"><img src={notificationImg} alt="" className="notify-img"/></NavLink>*/}
          {/*            </NavItem>*/}
          {/*          /!*<NavItem>*!/*/}
          {/*          /!*  <NavLink href="#">Login</NavLink>*!/*/}
          {/*          /!*</NavItem>*!/*/}
          {/*        </Nav>*/}
          {/*      </div>*/}
          {/*    )}*/}
          {/*  </Container>*/}
          {/*</Navbar>*/}



        </div>
      );
}

export default NavTop;
