import React from 'react';
import style from './userReview.module.css';
import Slider from 'react-slick';
import userRating from '../../../assets/images/user-review-ratng-star.png';
import user1 from '../../../assets/images/client1.png';
import user2 from '../../../assets/images/client2.png';
import user3 from '../../../assets/images/client3.png';
import user4 from '../../../assets/images/client4.png';
import user5 from '../../../assets/images/client5.png';
import user6 from '../../../assets/images/client6.png';


const UserReview =() =>{

    const items = [
        {
            src: user1,
            content: "Atricent has given me back hours! No more endless scrolling; the personalized feed gets it right. The virtual try-on has saved me from so many returns. The AI Stylist recommends so good outfits. Overall the app is extremely good and I wonder how nobody has thought of this yet!",
            name: "Alex",
            key: 1,

        },
        {
            src: user2,
            content: "I'm not a fashion guru, but Atricent made me feel like one. The virtual try-on feature? Brilliant! It's a game-changer for a non fashion guy like me. The AI Stylist, spot on with suggestions. I am in love with this app like actually. Kudos for turning a tech-lover into a trendsetter!",
            name: "Leo L",
            key: 2,

        },
        {
            src: user3,
            content: "Atricent has transformed my wardrobe game! The personalized feed feels like my own fashion fairytale, and the AI Stylist? Pure magic! It's like having a personal shopper whispering style tips. My Digital Wardrobe is now my daily inspiration. I can finally feel confident every single day. How did no one think of this before? ",
            name: "Amber",
            key: 3,

        },
        {
            src: user4,
            content: "Atricent isn't just an app; it's a style community. I've connected with fashionistas globally, sharing and getting inspired. The influencer collaborations are genius! It's like shopping with my favorite influencers. This has turned plain old boring online shopping to a social adventure.",
            name: "Grace",
            key: 4,

        },
        {
            src: user5,
            content: "Atricent helped me break free from guessing every single morning. The personalized feed understands my style better than I do! The virtual try-on is finally done right. Now, every choice feels intentional. It has turned my 30 minutes long 'what to wear' moments into 5 seconds of pressing a button.",
            name: "Antoni",
            key: 5

        },

        {
            src: user6,
            content: "Atricent gets me! The digital wardrobe is like my secret weapon. The AI Stylist suggests outfits I wouldn't think of, and then I can easily share them with my friends. It brings me back to the old Polyvore days. Amazing app, very well done.",
            name: "Isabella",
            key: 6,

        },

    ];
    const CustomPrevArrow = (props) => (
        <div
            className={`${style.cstm_prev_arrow}`}
            onClick={props.onClick}
        >
            <i className="fas fa-angle-left"></i>
        </div>
    );

    const CustomNextArrow = (props) => (
        <div
            className={`${style.cstm_next_arrow}`}
            onClick={props.onClick}
        >
            <i className="fas fa-angle-right"></i>

        </div>
    );

        var settings = {
            dots: false,
            infinite: true,
            arrows: true,
            speed: 3000,
            slidesToShow:2,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 3000,
            nextArrow: <CustomNextArrow />,
            prevArrow: <CustomPrevArrow />,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                    },
                },

            ],
        };



    return(
        <div>
            <section className={`${style.user_review_section} user-review-slider position-relative`}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className={`${style.client_heading}`}>
                            <h1>What our users say</h1>
                            <p>Crafted by Us, Inspired by You! At Atricent, we're just like you – ordinary people on an extraordinary fashion journey.
                                Your positive feedback fuels our passion. Everything we do is for you, our incredible community.
                                Your style, your sanctuary – Atricent is where fashion meets the extraordinary, inspired by each and every one of you.</p>
                        </div>
                        </div>
                    </div>
                    <div className="row">
                        <Slider {...settings}>
                            {items.map((slide, index) => (
                                <div className="col-md-4" key={index}>

                                    <div className={`${style.user_review}`}>
                                        <img src={slide.src} alt="" className={`${style.user_avatar}`}/>
                                        <div className={`${style.user_review_text}`}>
                                            <p>{slide.content} </p>
                                            <h2>{slide.name} </h2>
                                            <img src={userRating} alt="" className={style.user_rating}/>
                                        </div>
                                    </div>



                                </div>
                            ))}
                        </Slider>


                    </div>
                </div>
            </section>

        </div>
    )

}
export default UserReview;