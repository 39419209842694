import React from 'react';
import style from './features.module.css';
import aiImage from '../../../assets/images/ai.svg'
import aiStylist from '../../../assets/images/ai-stylist.svg'
import wardropImage from '../../../assets/images/digital-wardrob.svg';
import socialImage from '../../../assets/images/social-features.svg';
import virtualTryOn from '../../../assets/images/virtual-try-on.svg'
const Features = () =>{
    return(
        <div>
            <section className={`${style.features_section}`}>
                <div className={`${style.features_container} container`}>
                    <div className={`${style.features_bg}`}>
                    <h1 className="mb-3">Our Features</h1>
                        <div className={`${style.feature_list}`}>
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className={`${style.feature_info}`}>
                                        <img src={aiImage} alt=""/>
                                            <h2 className="mb-3">Personalization AI</h2>
                                        <p><span>Your personal haven:</span> AI crafts a feed tailored just for you – Products, Posts,
                                            Outfits, and Lists, all uniquely aligned with your preferences</p>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className={`${style.feature_info}`}>
                                        <img src={virtualTryOn} alt=""/>
                                        <h2 className="mb-3">Virtual Try-On</h2>
                                        <p><span>Visualize your style:</span> Virtually Try On Clothes simply by taking a picture of you and never worry about returns or the item not looking good on you!</p>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className={`${style.feature_info}`}>
                                        <img src={aiStylist} alt=""/>
                                        <h2 className="mb-3">AI Stylist</h2>
                                        <p><span>Your fashion guru:</span> AI Stylist crafts outfits for you based on occasion, weather, and your unique style. Enjoy effortless style every day, every time!</p>
                                    </div>
                                </div>

                            </div>
                            <div className="row mt-lg-4 mt-sm-0">
                                <div className="col-lg-2">

                                </div>
                                <div className="col-lg-4">
                                    <div className={`${style.feature_info}`}>
                                        <img src={wardropImage} alt=""/>
                                        <h2 className="mb-3">Digital Wardrobe</h2>
                                        <p><span>Your fashion sanctuary:</span> Organize all your clothes in a digital wardrobe. Mix and Match here to create trail-blazing outfits or just let our AI do it for you!</p>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className={`${style.feature_info}`}>
                                        <img src={socialImage} alt=""/>
                                        <h2 className="mb-3">Social Features</h2>
                                        <p><span>Your fashion village:</span> Share the stunning outfits you create with others and inspire or get inspired in this village. Your style tribe is waiting.</p>
                                    </div>
                                </div>
                                <div className="col-md-2">

                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    )

}
export default Features;