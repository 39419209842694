import React,{ useEffect } from 'react';
import NavTop from '../Dashboard/Navbar/navbar';
import SearchFilter from "./searchFilter/searchFilter";
import Feeds from "./Feeds/feeds";
import Papular from "./papular/papular";
import FlashSale from "./flashSale/flashSale";
import Email from '../Dashboard/emailSection/email'
import Mobile from '../Dashboard/mobilesection/mobile';
import Contact from '../Dashboard/contact us/contact';
import { useLocation } from 'react-router-dom';

const Home = () => {
    // const { pathname } = useLocation();
    // useEffect(() => {
    //     window.scrollTo(0, 0);
    // }, [pathname]);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    return (
        <>
            <NavTop />
            <SearchFilter/>
            <Feeds/>
            <Papular/>
            <FlashSale/>
            <Email />
            <Mobile />
            <Contact />
        </>
    )
}

export default Home