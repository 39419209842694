import React from 'react';
import style from './whatWeDo.module.css';
import sectionImage from '../../../assets/images/what-we-do.png';
import mobileSectionImage from '../../../assets/images/what-we-do-mob.png';
const WhatWeDo =() =>{
    return(
        <div>
            <section className={`${style.what_we_do_section}`}>
                <div className={`${style.what_we_do_container} container`}>
                    <div className={`${style.bg_section}`}>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className={`${style.what_we_do_text}`}>
                                    <h1>What we do</h1>
                                    <p>Embark on a personalized journey within your <span>fashion sanctuary</span> with <span>Atricent,</span> an <span>AI-Powered Social Fashion Platform.</span> Where we seamlessly blend cutting-edge technology,
                                        our AI Platform <span>redefines online shopping,</span> offering a <span>unique fusion</span> of AI-driven personalization, virtual try-on experiences, and social connectivity.
                                         <span> Craft your style</span> effortlessly with an AI Stylist suggesting outfits tailored to your taste, while the Digital Wardrobe becomes your <span>fashion haven.</span>
                                    </p>
                                    <h2>Our Mission</h2>
                                    <p className="mb-3">
                                        To empower individuals to <span>curate a wardrobe</span> that amplifies their <span>unique identity.</span> Atricent goes <span>beyond fashion,</span> fostering <span>a vibrant community</span> where users share their style,
                                        inspire others, and collectively redefine the <span>essence of self-expression.</span> Join us in this revolution where fashion becomes a statement, and every choice is a <span>celebration of individuality.</span>
                                    </p>
                                    <a href="https://atricent.com/apps" target="_blank" className={`${style.download_btn}`} rel="noopener noreferrer">Download Now</a>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className={`${style.what_we_do_img}`}>
                                    <img src={sectionImage} alt="" className={`${style.desktop_banner}`}  />
                                    <img src={mobileSectionImage} alt="" className={`${style.mobile_banner}`} />
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </section>

        </div>
    );

}
export default WhatWeDo;