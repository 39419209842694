import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
const rootURL = process.env.REACT_APP_PUBLIC_URL || 'http://10.5.10.92:2854/api/v1';

export const overviewApi = createApi({

    reducerPath: "overviewApi",
    baseQuery: fetchBaseQuery({
        baseUrl: rootURL,
        prepareHeaders: (headers, { getState }) => {
            const localToken = JSON.parse(localStorage.getItem('adminToken'))
            if (localToken !== null) {
                if (localToken) {
                    headers.set("Authorization", `${localToken}`);
                }
            }
            headers.set("Accept", "application/json");
            return headers;
        },
    }),

    // tagTypes: ['users', 'patients', 'singlePatient', 'organization', 'facility', 'organizationFacility', 'facilitators'],

    endpoints: (builder) => ({
        userLikeGuest: builder.mutation({
            query: (data) => ({
                url: "user-liked-guest",
                method: "post",
                body: data,
            }),
        }),
        userSubscription: builder.mutation({
            query: (data) => ({
                url: "user/subscribe",
                method: "post",
                body: data,
            }),
        }),
        getQuiz: builder.query({
            query: (url) => ({
                url: `${url}`,
                method: "get",
            }),
            // providesTags: ["patients", "singlePatient"],
        }),

    }),
})

export const {
    useUserLikeGuestMutation,
    useUserSubscriptionMutation,
    useGetQuizQuery,

} = overviewApi;