import React from 'react';
import style from './whoWeAre.module.css';
import ourWhyImage from '../../../assets/images/our-why.png';
import ourWhyMob from'../../../assets/images/our-why-mob.png';
import whoWeAre from '../../../assets/images/who-we-are.png';
import whoWeAreMob from '../../../assets/images/who-we-are-mob.png';
const WhoWeAre = () =>{
    return(
        <div>
            <section className={`${style.our_why_section}`}>
                <div className={`${style.cstm_container} container`}>
                    <div className={`${style.desktop_hide}`}>
                    <div className="row">
                        <div className="col-md-6">

                            <div className={`${style.our_why_text}`}>
                                <h1 className="mb-3">Our Why</h1>
                                <p className="mb-3">Our why is to make you feel your <span>best every single day.</span> Tired of the fashion <span>guesswork? </span>Atricent empowers you to confidently <span>embrace</span> your <span>distinctive style.</span> Ever
                                    bought something you <span>only wore once?</span> Felt uncertain about your outfit choices? <span>Scrolled endlessly,</span> finding <span>nothing</span> that <span>truly speaks to you?</span> Atricent is your
                                    personal style curator, a <span>companion</span> that understands <span>your fashion language.</span> Choose Atricent, where every fashion choice is a <span>celebration of your individuality.</span>
                                    Your style, your sanctuary, your statement - embrace the Atricent difference today!</p>
                                <a href="https://atricent.com/apps" target="_blank" rel="noopener noreferrer">Download Now</a>
                            </div>

                        </div>
                        <div className="col-md-6">

                            <div className={`${style.our_why_img}`}>
                                <img src={ourWhyMob} alt=""/>
                            </div>
                        </div>

                    </div>
                    </div>
                    <div className={`${style.mob_hide}`}>
                        <div className="row">
                            <div className="col-md-12 position-relative d-flex p-0">

                                <div className={`${style.our_why_img}`}>
                                    <img src={ourWhyImage} alt=""/>
                                </div>
                                <div className={`${style.our_why_text} `}>
                                    <h1 className="mb-3">Our Why</h1>
                                    <p className="mb-3">Our why is to make you feel your <span>best every single day.</span> Tired of the fashion <span>guesswork? </span>
                                        Atricent empowers you to confidently <span>embrace</span> your <span>distinctive style.</span> Ever
                                        bought something you <span>only wore once?</span> Felt uncertain about your outfit choices? <span>Scrolled endlessly,</span> finding <span>nothing</span> that <span>truly speaks to you?</span> Atricent is your
                                        personal style curator, a <span>companion</span> that understands <span>your fashion language.</span> Choose Atricent, where every fashion choice is a <span>celebration of your individuality.</span>
                                        Your style, your sanctuary, your statement - embrace the Atricent difference today!</p>
                                    <a href="https://atricent.com/apps" target="_blank" rel="noopener noreferrer">Download Now</a>
                                </div>
                            </div>
                            <div className="col-md-5 p-0">



                            </div>
                        </div>
                    </div>

                </div>

            </section>


            <section className={`${style.who_we_are_section}`}>
                <div className={`${style.cstm_container} container`}>
                    <div className={`${style.desktop_hide}`}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className={`${style.who_we_are_text}`}>
                                    <h1 className="mb-3">Who We Are</h1>
                                    <p> We're just like you – <span>Ordinary people</span> tired of the <span>status quo.</span> Our team <span>spans the globe,</span> from California to London, Geneva to Hong Kong, Tokyo to Istanbul, and Lahore to Warsaw.</p>
                                    <p className="mb-5"> <span>Passionate</span> about both tech and fashion, we're committed to crafting a solution that people truly want. Atricent is born from the <span>shared frustration </span>of current solutions
                                        and the <span>collective desire</span> to usher in a change. Join us, the <span>ordinary folks</span> with an extraordinary vision, as we redefine the intersection of fashion and technology. Your <span>style revolution</span> begins with us!</p>
                                    <a href="https://atricent.com/apps" target="_blank" rel="noopener noreferrer">Download Now</a>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className={`${style.who_we_are_img}`}>
                                    <img src={whoWeAreMob} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`${style.mob_hide}`}>
                        <div className="row">
                            <div className="col-md-12 position-relative d-flex">
                                <div className={`${style.who_we_are_text}`}>
                                    <h1 className="mb-3">Who We Are</h1>
                                    <p> We're just like you – <span>Ordinary people</span> tired of the <span>status quo.</span> Our team <span>spans the globe,</span> from California to London, Geneva to Hong Kong, Tokyo to Istanbul, and Lahore to Warsaw.</p>
                                    <p className="mb-3"> <span>Passionate</span> about both tech and fashion, we're committed to crafting a solution that people truly want. Atricent is born from the <span>shared frustration </span>of current solutions
                                        and the <span>collective desire</span> to usher in a change. Join us, the <span>ordinary folks</span> with an extraordinary vision, as we redefine the intersection of fashion and technology. Your <span>style revolution</span> begins with us!</p>
                                    <a href="https://atricent.com/apps" target="_blank" rel="noopener noreferrer">Download Now</a>

                                </div>
                                <div className={`${style.who_we_are_img}`}>
                                    <img src={whoWeAre} alt=""/>
                                </div>
                            </div>
                            <div className="col-md-6">

                            </div>
                        </div>
                    </div>


                </div>

            </section>
        </div>
    )
}
export default WhoWeAre;