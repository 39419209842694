import React, {useState, useEffect} from 'react'
import style from './discoverSearch.module.css';
import searchImage from '../../../assets/images/search-frame.svg';
import "react-range-slider-input/dist/style.css";
import { useDispatch } from 'react-redux';
import { setDiscoverDropdownSearch } from '../../../store/reducers/Slice';
const DiscoverSearch = () => {
    const dispatch = useDispatch()
    const [selectedDropdownValue, setSelectedDropdownValue] = useState('Account'); // Initial text for the button
    const [searchValue, setSearchValue] = useState('');

    const handleSearchInputChange = (event) => {
        const inputValue = event.target.value;
        setSearchValue(inputValue);

        // Check if the input value length is at least two characters
        if (inputValue.length >= 2) {
            console.log('At least two characters entered:', inputValue);
            dispatch(setDiscoverDropdownSearch(selectedDropdownValue))
        }else{

        }
    };
    // Function to handle click event of dropdown items
    const handleDropdownItemClick = (value) => {
        setSelectedDropdownValue(value); // Update the selected value


    };
    return (
        <>
            <section className={`${style.search_filter_section}`}>
                <div className={`container-fluid ${style.search_filter_container}`}>
                    <div className={`position-relative ${style.search_filter_input}`}>
                        <input
                            type="text"
                            className={`${style.cstm_search}`}
                            placeholder="Search"
                            value={searchValue}
                            onChange={handleSearchInputChange}
                        />
                        <img src={searchImage} alt="" className={`${style.search_img}`}/>
                            <div className="dropdown position-static">
                                <button
                                    className={`btn dropdown-toggle ${style.filters_dropdown}`}
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    {selectedDropdownValue} {/* Display selected value in the button */}
                                </button>
                                <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" href="#" onClick={() => handleDropdownItemClick('Account')}>Account</a></li>
                                    <li><a className="dropdown-item" href="#" onClick={() => handleDropdownItemClick('Feed')}>Feed</a></li>
                                    <li><a className="dropdown-item" href="#" onClick={() => handleDropdownItemClick('Outfit')}>Outfit</a></li>
                                    <li><a className="dropdown-item" href="#" onClick={() => handleDropdownItemClick('List')}>List</a></li>
                                </ul>
                            </div>


                    </div>

                </div>
            </section>
        </>
    )
}

export default DiscoverSearch;