import React, { useState, useEffect } from 'react'
import style from './productDetail.module.css';
import ProductInfo from "./productInfo/productInfo";
import NavTop from '../Dashboard/Navbar/navbar';
import Email from '../Dashboard/emailSection/email';
import Mobile from '../Dashboard/mobilesection/mobile';
import Contact from '../Dashboard/contact us/contact';
import LikeProducts from "./likeProducts/likeProducts";
import {useLocation} from "react-router";

const ProductDetail = () => {
    // const { pathname } = useLocation();
    // useEffect(() => {
    //     window.scrollTo(0, 0);
    // }, [pathname]);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);
    return (
        <>
            <NavTop />
            <ProductInfo/>
            <LikeProducts/>
            <Email />
            <Mobile />
            <Contact />
        </>
    )
}

export default ProductDetail;