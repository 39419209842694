import axiosInstance from "./AxiosRequest";

export const postWithLiveRequest = async (endpoint, data) => {
    try {
       
            const response = await axiosInstance.post(endpoint, data);
            return response;
        
    } catch (error) {
        console.error("postRequest error:", error);
        throw error;
    }
};