import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    discoverDropdownSearch: 'Account',

}

export const overviewSlice = createSlice({
    name: 'overview',
    initialState,
    reducers: {
        setDiscoverDropdownSearch: (state, action) => {
            state.discoverDropdownSearch = action.payload
        },

    }
})

export const {
    setDiscoverDropdownSearch,

} = overviewSlice.actions

export default overviewSlice.reducer