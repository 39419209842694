import React from 'react';
import style from './landingNav.module.css';
import logoImage from '../../../assets/images/logo.svg';
const LandingNav = ()=>{

    return (
        <div>
            <section className={`${style.landing_page_navbar}`}>
                <div className={`${style.cstm_nav_container} container`}>
                    <nav className="navbar navbar-expand-lg">
                        <div className="container-fluid p-0">
                            <a className="navbar-brand" href="#" aria-label="Home">
                                <img src={logoImage} alt="Atricent Logo" className={`${style.logo}`}/>
                            </a>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                    aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className={`${style.cstm_nav} navbar-nav ms-auto mb-2 mb-lg-0`}>
                                    <li className="nav-item">
                                        <a className="nav-link active" aria-current="page" href="mailto:hello@atricent.com">Contact Us</a>
                                    </li>



                                </ul>

                            </div>
                        </div>
                    </nav>
                </div>

            </section>
        </div>
    )

}
export default LandingNav;