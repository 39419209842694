import React, { useState, useEffect } from 'react';
import style from './accounts.module.css';
import profileImage1 from '../../../assets/images/profile1.png';
import profileImage2 from '../../../assets/images/profile2.png';
import profileImage3 from '../../../assets/images/profile3.png';
import profileImage4 from '../../../assets/images/profile4.png';
import profileImage5 from '../../../assets/images/profile5.png';
import profileImage6 from '../../../assets/images/profile6.png';
import profileImage7 from '../../../assets/images/profile7.png';
import profileImage8 from '../../../assets/images/profile8.png';
import profileImage9 from '../../../assets/images/profile9.png';
import profileImage10 from '../../../assets/images/profile10.png';
const Accounts = ()=> {

    return(
        <>
            <section className={`${style.account_section}`}>
                <div className={`container-fluid ${style.cstm_account_container}`}>
                    <div className={`${style.account_listing}`}>
                        <ul className="p-0">
                            <li>
                                <img src={profileImage1} alt="profile1" className="mb-4"/>
                                <p>Antoni Stebelski</p>
                                <span>Antonisteb</span>
                            </li>
                            <li>
                                <img src={profileImage2} alt="profile1" className="mb-4"/>
                                <p>Antoni Stebelski</p>
                                <span>Antonisteb</span>
                            </li>
                            <li>
                                <img src={profileImage3} alt="profile1" className="mb-4"/>
                                <p>Antoni Stebelski</p>
                                <span>Antonisteb</span>
                            </li>
                            <li>
                                <img src={profileImage4} alt="profile1" className="mb-4"/>
                                <p>Antoni Stebelski</p>
                                <span>Antonisteb</span>
                            </li>
                            <li>
                                <img src={profileImage5} alt="profile1" className="mb-4"/>
                                <p>Antoni Stebelski</p>
                                <span>Antonisteb</span>
                            </li>
                            <li>
                                <img src={profileImage6} alt="profile1" className="mb-4"/>
                                <p>Antoni Stebelski</p>
                                <span>Antonisteb</span>
                            </li>
                            <li>
                                <img src={profileImage7} alt="profile1" className="mb-4"/>
                                <p>Antoni Stebelski</p>
                                <span>Antonisteb</span>
                            </li>
                            <li>
                                <img src={profileImage8} alt="profile1" className="mb-4"/>
                                <p>Antoni Stebelski</p>
                                <span>Antonisteb</span>
                            </li>
                            <li>
                                <img src={profileImage9} alt="profile1" className="mb-4"/>
                                <p>Antoni Stebelski</p>
                                <span>Antonisteb</span>
                            </li>
                            <li>
                                <img src={profileImage10} alt="profile1" className="mb-4"/>
                                <p>Antoni Stebelski</p>
                                <span>Antonisteb</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            </>
    )
}
export default Accounts;