import React from 'react';
import style from './banner.module.css';
import bannerImg from '../../../assets/images/banner-img-resized.png';
// import bannerMob from '../../../assets/images/banner-mob.png';
import marker from '../../../assets/images/marker.svg';
import appStoreImg from '../../../assets/images/app-store-img.svg';
import playStoreImg from '../../../assets/images/google-playstore-img.svg';
import LandingNav from "../landingNav/landingNav";
const Banner = () => {

    return(
        <div>
            <div className={`${style.mob_banner_bg}`}>
            <LandingNav/>
            <section className={`${style.banner_section}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className={`${style.banner_text}`}>
                                <h1 className="position-relative">Discover Your<br/> Fashion Sanctuary<span><img src={marker} alt=""/> </span></h1>
                                <p>Explore a personally <span>curated feed just for you,</span> with some <span>virtual try-on flair,</span> and a touch of <span>AI Stylist magic. </span>
                                    Atricent is not just fashion; it's a <span>social statement.</span> Share your style, inspire others, and be part of a <span>new revolution.</span>
                                </p>
                                <ul className="p-0 mb-0">
                                    <li>
                                        <a
                                            href="https://apps.apple.com/gb/app/atricent-ai-fashion-platform/id6448105847"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
            <span className="visually-hidden">
                Download Atricent AI Fashion Platform on the App Store
            </span>
                                            <img src={appStoreImg} alt="Atricent AI Fashion Platform on the App Store" />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://play.google.com/store/apps/details?id=com.atricent&hl=en_US"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
            <span className="visually-hidden">
                Download Atricent AI Fashion Platform on Google Play
            </span>
                                            <img src={playStoreImg} alt="Atricent AI Fashion Platform on Google Play" />
                                        </a>
                                    </li>
                                </ul>

                                <a href="https://atricent.com/apps" target="_blank" rel="noopener noreferrer" className={`${style.mob_download_btn}`}>Download Now</a>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className={`${style.banner_img} `}>
                             <img src={bannerImg} alt="" className={`${style.mob_hide}`} />
                             <img src={bannerImg} alt="" className={`${style.desktop_hide}`} />
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            </div>
        </div>
    )

}
export default Banner;