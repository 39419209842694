import React from 'react';
import style from './footer.module.css';
import instagram from '../../../assets/images/instagram.svg';
import youtube from '../../../assets/images/youtube.svg';
import tiktok from '../../../assets/images/tiktok.svg';
const Footer = ()=>{
    return(
        <div>
            <section className={`${style.footer_section}`}>
                <div className="container">

                        <div className="row">
                            <div className="col-md-12">
                                <div className={`${style.footer_content}`}>
                                    <p>© Terms and Conditions, Privacy Policy Atricent 2024</p>
                                    <ul>
                                        <li>
                                            <a href="https://www.instagram.com/atricenthq/" target="_blank" rel="noopener noreferrer" aria-label="Visit Atricent on Instagram">
                                                <img src={instagram} alt="Instagram"/>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.youtube.com/channel/UCQUP6oCVUA-wDDPiI0kegvg" target="_blank" rel="noopener noreferrer" aria-label="Visit Atricent on YouTube">
                                                <img src={youtube} alt="YouTube"/>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.tiktok.com/@atricent" target="_blank" rel="noopener noreferrer" aria-label="Visit Atricent on TikTok">
                                                <img src={tiktok} alt="TikTok"/>
                                            </a>
                                        </li>
                                    </ul>


                                </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )

}
export default Footer;