
import { useEffect, useLayoutEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import React from 'react';
import './App.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PrivacyPolicy from './Pages/PrivacyPolicy';
import EULA from './Pages/EULA';
import EmptyLayout from './Layouts/EmptyLayout';
import Dashboard from './Pages/Dashboard/dashboard';
import Landing from "./Pages/LandingPage/landing";
import Redirection from './Pages/redirection';
import Home from './Pages/Home/home';
import Discover from "./Pages/Discover/discover";
import ProductDetail from "./Pages/ProductDetail/productDetail";
import DeleteUser from './Pages/DeleteUser/DeleteUser';

const App = () => {
    // const location = useLocation();
    //
    // useLayoutEffect(() => {
    //     window.scrollTo(0, 0); // Scrolls to the top on route change
    // }, [location.pathname]);

    return (
        <Routes>
            <Route index element={<Landing />} />
            <Route path='/delete-user' element={<DeleteUser />} />

            <Route path='/home' element={<Home />} />
            <Route path='/discover' element={<Discover />} />
            <Route path='/product-detail' element={<ProductDetail />} />
            <Route path='/apps' element={<Redirection />} />
            <Route path="/" element={<EmptyLayout />} >
                <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                <Route path='/eula' element={<EULA />} />
            </Route>
        </Routes>
    );
};

export default App;

