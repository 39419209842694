import React, {useState, useEffect} from 'react'
import style from './searchFilter.module.css';
import searchImage from '../../../assets/images/search-frame.svg';
import filterImage from '../../../assets/images/filters-icon.svg';
import rightArrow from '../../../assets/images/right-arrow.svg';
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import { useLocation } from 'react-router-dom';
const SearchFilter = () => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedSizeOption, setSelectedSizeOption] = useState(null);
    const [selectedBrandOption, setSelectedBrandOption] = useState(null);
    const [showDropdown, setShowDropdown] = useState(false);
    const [actionbtn, setActionBtn] = useState(false);
    const [filters, setFilters] = useState(false);
    const [value, setValue] = useState([30, 60]);
    const location = useLocation();
    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setActionBtn(true);
    };
    const handleSizeOptionClick = (option) => {
        setSelectedSizeOption(option);
        setActionBtn(true);
    };
    const handleBrandOptionClick = (option) => {
        setSelectedBrandOption(option);
        setActionBtn(true);
    };
    const clear = () => {
        setSelectedOption(null);
        setSelectedSizeOption(null);
        setSelectedBrandOption( null);
    };
    const togglefilters = () => {
      setFilters(value => !value);
        setSelectedOption(null);
        setSelectedSizeOption(null);
        setSelectedBrandOption( null);
        setActionBtn(false);
    };
    useEffect(() => {
        // Logic to show/hide dropdown based on specific route
        // Example: Show dropdown only on '/specific-route'
        if (location.pathname === '/discover') {
            setShowDropdown(true);
        } else {
            setShowDropdown(false);
        }
    }, [location]);
    return (
        <>
            <section className={`${style.search_filter_section}`}>
                <div className={`container-fluid ${style.search_filter_container}`}>
                    <div className={`position-relative ${style.search_filter_input}`}>
                        <input type="text" className={`${style.cstm_search}`} placeholder="search"/>
                        <img src={searchImage} alt="" className={`${style.search_img}`}/>
                        {showDropdown ? (
                            <div className="dropdown position-static">
                                <button className={`btn dropdown-toggle ${style.filters_dropdown}`}  type="button"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                    Account
                                </button>
                                <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" href="#">Action</a></li>
                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                                </ul>
                            </div>
                        ):(
                            <button className={`${style.filters_btn}`} onClick={togglefilters}>  <img src={filterImage} alt="" className={`${style.filter_img}`}/>Filters</button>

                        )}

                    </div>

                    {filters &&

                        <>
                            <div className={`${style.cstm_filters}`}>
                                <h1>Sort</h1>
                                <div className={`${style.default_filters}`}>
                                    <ul>
                                        <li onClick={() => handleOptionClick('Price (Low to High)')}><span className={`${selectedOption === 'Price (Low to High)' ? style.black_bubble : style.grey_bubble }`}></span>Price (Low to High)</li>
                                        <li onClick={() => handleOptionClick('Price (High to Low)')}><span className={` ${selectedOption === 'Price (High to Low)' ? style.black_bubble : style.grey_bubble}`}></span>Price (High to Low)</li>
                                        <li onClick={() => handleOptionClick('New Arrival')}><span className={` ${selectedOption === 'New Arrival' ? style.black_bubble : style.grey_bubble}`}></span>New Arrival</li>
                                        <li onClick={() => handleOptionClick('Top Rated')}><span className={` ${selectedOption === 'Top Rated' ? style.black_bubble : style.grey_bubble}`}></span>Top Rated</li>
                                        <li onClick={() => handleOptionClick('A-Z')}><span className={` ${selectedOption === 'A-Z' ? style.black_bubble : style.grey_bubble}`}></span>A-Z</li>
                                        <li onClick={() => handleOptionClick('Z-A')}><span className={` ${selectedOption === 'Z-A' ? style.black_bubble : style.grey_bubble}`}></span>Z-A</li>
                                    </ul>
                                </div>
                                <h1>Sort</h1>
                                <div className="row">

                                    <div className="col-md-5">

                                        <div className={`${style.filters_by_size}`}>

                                            <ul>
                                                <li onClick={() => handleSizeOptionClick('XS')} className={`${selectedSizeOption === 'XS' ? style.size_active : '' }`}>XS <span>(19)</span></li>
                                                <li onClick={() => handleSizeOptionClick('S')} className={`${selectedSizeOption === 'S' ?  style.size_active : '' }`}>S <span>(14)</span></li>
                                                <li onClick={() => handleSizeOptionClick('M')} className={`${selectedSizeOption === 'M' ?  style.size_active : '' }`}>M <span>(15)</span></li>
                                                <li onClick={() => handleSizeOptionClick('L')} className={`${selectedSizeOption === 'L' ?  style.size_active : ''}`}>L <span>(16)</span></li>
                                                <li onClick={() => handleSizeOptionClick('XL')} className={`${selectedSizeOption === 'XL' ?  style.size_active : '' }`}>XL <span>(13)</span></li>

                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <div className={`${style.filters_by_brands}`}>
                                            <ul>
                                                <li onClick={() => handleBrandOptionClick('Uniqlo')} className={`${selectedBrandOption === 'Uniqlo' ? style.brand_active : '' }`}>Uniqlo <span>(49)</span></li>
                                                <li onClick={() => handleBrandOptionClick('H&M')} className={`${selectedBrandOption === 'H&M' ? style.brand_active : '' }`}>H&M <span>(37)</span></li>
                                                <li onClick={() => handleBrandOptionClick('Zara')} className={`${selectedBrandOption === 'Zara' ? style.brand_active : '' }`}>Zara <span>(33)</span></li>
                                                <li onClick={() => handleBrandOptionClick('GAP')} className={`${selectedBrandOption === 'GAP' ? style.brand_active : '' }`}>GAP <span>(19)</span></li>
                                                <li onClick={() => handleBrandOptionClick('Nike')} className={`${selectedBrandOption === 'Nike' ? style.brand_active : '' }`}>Nike <span>(17)</span></li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <h1>Price</h1>
                                    <div className={`${style.price_range}`}>
                                <RangeSlider min={0} max={1000} step={50} value={value} onInput={setValue}/>
                                        <span className={`${style.minimum}`}>${value[0]}</span> <span className={`${style.maximum}`}>${value[1]}</span>
                                    </div>
                                </div>

                                {actionbtn &&
                                <>
                                    <div className={`${style.action_btns}`}>
                                        <button className={`${style.clear_btn}`} onClick={clear}>Clear</button>
                                        <button className={`${style.apply_btn}`}>Apply <img src={rightArrow} alt=""/> </button>
                                    </div>
                                </>
                                }


                            </div>
                        </>
                    }




                </div>
            </section>
        </>
    )
}

export default SearchFilter