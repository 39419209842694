import React, {useState} from 'react'
import './email.css'
import {  Row } from 'reactstrap';
import { useUserSubscriptionMutation} from '../../../store/api/API'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Spinner} from "reactstrap";
const Email = () => {
const [email,setEmail]= useState('');
const [showLoader, setShowLoader] = useState(false)
const [userSubscribe] = useUserSubscriptionMutation();
    const handleSubmit = async (e)=>{
       e.preventDefault();
        setShowLoader(true);
        let req={email:email};
        const response = await userSubscribe(req);
        if(response.data){
     toast(response.data.message);
     setEmail('');
        }else{
            toast("Something went wrong");
            setEmail('');

        }
        setShowLoader(false);
    }
  return (
      <section className="email-section">
   <div className='main-email-container'>
     <div className='email-container'>
        <Row className='main-email-row'>
            <Row className='main-email-row-first'>
                <h1>Join the waiting list.</h1>
            </Row>
            <Row className='main-email-row-second'>
                <p>Enter your email to get enrolled in our newsletter and updates.</p>
            </Row>
            <div className='main-email-row-third'>
                <div className='email-placeholder position-relative'>
                    <form onSubmit={handleSubmit}>
                    <input type="email" className="cstm-email-input" value={email} placeholder="Email" required={true} onChange={(e) => setEmail(e.target.value)}/>

                    {/*<p className='email-txt'>Email</p>*/}
                        {showLoader ?
                            (
                                <>
                                    <button type="submit" className='email-btn'>
                                        <Spinner size="" color="secondary" style={{
                                            height: '2rem',
                                            width: '2rem'
                                        }}>
                                            Loading...
                                        </Spinner>
                                    </button>

                                </>
                            ):(
                                <>
                                    <button type="submit" className='email-btn'>Send</button>
                                </>
                            )}

                    </form>
                </div>
            </div>
        </Row>
    </div>
   </div>
      </section>
  )
}

export default Email