import React, { useState, useEffect } from 'react';
import style from './likeProducts.module.css';
import Slider from 'react-slick';

import productImage from "../../../assets/images/product-image.png";
import dotImage from "../../../assets/images/three-dots.svg";
import redLikeImage from "../../../assets/images/red-like.svg";
import ratingImage from "../../../assets/images/white-rating.svg";
import likeImage from "../../../assets/images/white-like.svg";

import leftArrow from "../../../assets/images/left-arrow.png"
import rightArrow from "../../../assets/images/right-arrow.png"
const LikeProducts = () =>{
    const CustomPrevArrow = (props) => (
        <div
            className={`${style.prev_arrow}`}
            onClick={props.onClick}
        >
            <img src={leftArrow} alt="" />
        </div>
    );

    const CustomNextArrow = (props) => (
        <div
            className={`${style.next_arrow}`}
            onClick={props.onClick}
        >
            <img src={rightArrow} alt="" />
        </div>
    );

    var settings = {
        dots: false,
        infinite: true,
        arrows: true,
        speed: 1000,
        slidesToShow:3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
    };
    return (
        <>
            <section className={`${style.like_products} position-relative`}>
                <div className={`container-fluid ${style.like_products_container}`}>
                    <div className={`${style.like_title}`}>
                        <h1>You may also like</h1>
                    </div>


                    <div  className={`${style.products_listing} mt-5`}>
                        <div className="row">
                            <Slider {...settings}>
                            <div className='slide'>
                            <div className="">
                                <div  className={`position-relative ${style.product_bg}`}>
                                    <div  className={`${style.product_img}`}>

                                        <img src={productImage} alt="" />
                                    </div>
                                    <div className={`${style.dot_img}`}>
                                        <img src={dotImage} alt="" />
                                    </div>
                                    <div className={`${style.like_img}`}>
                                        <img src={redLikeImage} alt="" />
                                    </div>

                                    <div  className={`${style.product_description}`}>
                                        <div className={`${style.product_cat_info}`}>
                                            <p><span  className={`${style.product_tag}`}>Uniqla</span>  <span  className={`${style.remaining}`}><span className={`${style.product_review}`}><img src={ratingImage} alt=""/>4.9(99 Reviews)</span> <img src={likeImage} alt=""/> 4.9</span></p>
                                            <p className="pt-2"><span className={`${style.product_title}`}>Relax Dry Stretch</span> <span  className={`${style.product_price}`}>$ 221</span></p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            </div>
                                <div className='slide'>
                                    <div className="">
                                        <div  className={`position-relative ${style.product_bg}`}>
                                            <div  className={`${style.product_img}`}>

                                                <img src={productImage} alt="" />
                                            </div>
                                            <div className={`${style.dot_img}`}>
                                                <img src={dotImage} alt="" />
                                            </div>
                                            <div className={`${style.like_img}`}>
                                                <img src={redLikeImage} alt="" />
                                            </div>

                                            <div  className={`${style.product_description}`}>
                                                <div className={`${style.product_cat_info}`}>
                                                    <p><span  className={`${style.product_tag}`}>Uniqla</span>  <span  className={`${style.remaining}`}><span className={`${style.product_review}`}><img src={ratingImage} alt=""/>4.9(99 Reviews)</span> <img src={likeImage} alt=""/> 4.9</span></p>
                                                    <p className="pt-2"><span className={`${style.product_title}`}>Relax Dry Stretch</span> <span  className={`${style.product_price}`}>$ 221</span></p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='slide'>
                                    <div className="">
                                        <div  className={`position-relative ${style.product_bg}`}>
                                            <div  className={`${style.product_img}`}>

                                                <img src={productImage} alt="" />
                                            </div>
                                            <div className={`${style.dot_img}`}>
                                                <img src={dotImage} alt="" />
                                            </div>
                                            <div className={`${style.like_img}`}>
                                                <img src={redLikeImage} alt="" />
                                            </div>

                                            <div  className={`${style.product_description}`}>
                                                <div className={`${style.product_cat_info}`}>
                                                    <p><span  className={`${style.product_tag}`}>Uniqla</span>  <span  className={`${style.remaining}`}><span className={`${style.product_review}`}><img src={ratingImage} alt=""/>4.9(99 Reviews)</span> <img src={likeImage} alt=""/> 4.9</span></p>
                                                    <p className="pt-2"><span className={`${style.product_title}`}>Relax Dry Stretch</span> <span  className={`${style.product_price}`}>$ 221</span></p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='slide'>
                                    <div className="">
                                        <div  className={`position-relative ${style.product_bg}`}>
                                            <div  className={`${style.product_img}`}>

                                                <img src={productImage} alt="" />
                                            </div>
                                            <div className={`${style.dot_img}`}>
                                                <img src={dotImage} alt="" />
                                            </div>
                                            <div className={`${style.like_img}`}>
                                                <img src={redLikeImage} alt="" />
                                            </div>

                                            <div  className={`${style.product_description}`}>
                                                <div className={`${style.product_cat_info}`}>
                                                    <p><span  className={`${style.product_tag}`}>Uniqla</span>  <span  className={`${style.remaining}`}><span className={`${style.product_review}`}><img src={ratingImage} alt=""/>4.9(99 Reviews)</span> <img src={likeImage} alt=""/> 4.9</span></p>
                                                    <p className="pt-2"><span className={`${style.product_title}`}>Relax Dry Stretch</span> <span  className={`${style.product_price}`}>$ 221</span></p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Slider>


                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default LikeProducts;