import React, { useState } from 'react'
import './DeleteUser.css';
import { postWithLiveRequest } from './../../services/NetworkRequest';




const DeleteUser = () => {

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otp, setOtp] = useState('');
  const [otpError, setOtpError] = useState(false);
  const [EmailForOtpApi, setEmailForOtpApi] = useState('');
  const [isVerified, setIsVerified] = useState(false);


  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = { email };

    try {
      const response = await postWithLiveRequest('deactivate-user', data);

      setEmailForOtpApi(response.data.email)
      setEmail('');
      setMessage('');

      setIsOtpSent(true);
      
    } catch (error) {
      console.error('Error:', error);

      setEmail('');
      setMessage('');
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    console.log('EmailForOtpApi', EmailForOtpApi)
    const data = { email:EmailForOtpApi, token: otp };

    try {
      const response = await postWithLiveRequest('deactivate-user-with-verify-otp', data);
      console.log('response', response);
      setOtp('')
      setOtpError(false);
      setIsVerified(true); // Set verification state to true to hide the form and show success message
    } catch (error) {
      console.error('Error:', error);
      setOtpError(true);
    }
  };



  return (
    <div className="App">
      <div className="login-container">
        {/* Step 1: Show email input form */}
        {!isOtpSent && !isVerified && (
          <form className="login-form" onSubmit={handleSubmit}>
            <h2>Delete User</h2>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input 
                type="email" 
                id="email"
                placeholder="Enter your email"
                required
                value={email} // Controlled input
                onChange={(e) => setEmail(e.target.value)} 
              />
            </div>

            <div className="form-group">
              <label htmlFor="message">Message:</label>
              <textarea
                id="message"
                placeholder="Write your message"
                required
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
            </div>
            <button type="submit" className="submit-btn">Submit</button>


          </form>
        )}

        {/* Step 2: Show OTP input form only if OTP is sent and not yet verified */}
        {isOtpSent && !isVerified && (
          <form className={`login-form ${otpError ? 'shake' : ''}`} onSubmit={handleOtpSubmit}>
            <h2>Enter OTP</h2>
            <div className="form-group">
              <label htmlFor="otp">OTP:</label>
              <input 
                type="text" 
                id="otp"
                placeholder="Enter OTP sent to your email"
                required
                value={otp} // Controlled input
                onChange={(e) => setOtp(e.target.value)} 
              />
            </div>
            <button type="submit" className="submit-btn">Verify OTP</button>
          </form>
        )}

        {/* Step 3: Show success message if OTP is verified */}
        {isVerified && (
          <div className="success-message">
            <h2>Success!</h2>
            <p>Your account has been deactivated.</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default DeleteUser